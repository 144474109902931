import React, { useRef, useState, useEffect, useContext } from "react";
import Services from '../network/Services'
import { UserContext } from "../App";
import commonLib from "../control/commonLib";

function ExtendValidity() {
  const { setIsLoading } = useContext(UserContext);
  const OrderId = window.location.search
  const paymentSuccesStatus = atob(commonLib.getQueryParameters(OrderId, 'TrxnStatus'))
  const stateObj = commonLib.getSessionStoarge("stateObj") !== "undefined" && commonLib.getSessionStoarge("stateObj") !== null ? commonLib.getObjFromStorage("stateObj") : {};
  const userPrimaryDetail = commonLib.getObjFromStorage("userdata");
  const appState = stateObj?.appState;
  const userId = stateObj?.userId;
  const roleId = stateObj?.roleId !== undefined ? JSON.parse(stateObj?.roleId) : [];
  const loginUserId = roleId[0]?.loginUserId;
  const loggenInId = stateObj?.loggenInId;
  const urlParams = new URLSearchParams(window.location.search);
  const ldStripeConnect = urlParams.get('ld_stripe_connect');

  useEffect(() => {
    functionforApiCall()
  }, [])

  const functionforApiCall = async () => {
    const userPrimaryDetail = commonLib.getObjFromStorage("userdata");
    
    // console.log('userPrimaryDetail', ldStripeConnect, paymentSuccesStatus)
    if (paymentSuccesStatus != "SUCCESS" && ldStripeConnect != 'success') {
      window.location.replace('/index')
    } else {
      await Services.apiCallScripePaymentConfirmation({ userEmail: userPrimaryDetail?.primaryEmailId })
      checkExpiryDate(loginUserId)
    }
  }



  const checkExpiryDate = () => {
    setIsLoading(true)
    let promises = Services.getExpirydatetime(loginUserId);
    promises
      .then(function (response) {
        setIsLoading(false)
        let data = response?.data?.data
        // console.log('datadatadatadata',data)
        if (paymentSuccesStatus == "SUCCESS" || ldStripeConnect == 'success') {
          const currentDate = new Date();
          const oneYearFromNow = new Date(currentDate);
          // oneYearFromNow.setDate(currentDate.getDate() + 365);
          oneYearFromNow.setDate(currentDate.getDate() + 365);
          upGradeValidityPeriod(data?.userValidityId, data?.loginUserid, data?.userId, data?.userRoleId, data?.roleId, oneYearFromNow, data?.userId)
        }
      })

      .catch((err) => {
        setIsLoading(false)
        alert("Something went wrong")
      });
  }
  const upGradeValidityPeriod = (userValidityId, loginUserid, userId, userRoleId, roleId, expriedDate, createdBy) => {
    setIsLoading(true)
    let promises = Services.putUpgradeValidity(userValidityId, loginUserid, userId, userRoleId, roleId, expriedDate, createdBy);
    promises
      .then(function (response) {
        setIsLoading(false)
        // window.location.replace('/index')
        updateAcademy()
      })
      .catch((err) => {
        alert("Something went wrong")
        setIsLoading(false);
      });

  }

  const updateAcademy = async () => {
    setIsLoading(true)
    const userPrimaryDetail = commonLib.getObjFromStorage("userdata");
    await Services.apiCallScripePaymentConfirmation({ userEmail: userPrimaryDetail?.primaryEmailId })
    window.location.replace('/index')
  }
    return (
      <></>
    )
}

export default ExtendValidity