import config from '../config.json'

const demo = config.DEMO;



// export const BASE_URL = (demo) ? "https://aointakeuatapi.azurewebsites.net" : "https://aointakeapi.azurewebsites.net";
// export const AoLoginBaseUrl = (demo) ? "https://aologinuat.azurewebsites.net/" : "https://aologin.azurewebsites.net/";
// export const AoIntakeBaseUrl = (demo) ? "https://aointakeformuat.azurewebsites.net/" : "https://aointakeform.azurewebsites.net/";
// export const LpoLiteBaseUrl = (demo) ? "https://lpoliteuat.azurewebsites.net/" : "https://lpolite.azurewebsites.net/";
// export const LpoLiteBaseUrl = (demo) ? "https://lpoliteuat.agingoptions.com/" : "https://lpolite.azurewebsites.net/";
// export const EventBaseUrl = (demo) ? "https://eventuat.azurewebsites.net/" : "https://eventdev.azurewebsites.net/";
// export const CounterBaseUrl = (demo) ? "https://aoeventcounteruat.azurewebsites.net/" : "https://aoeventcounter.azurewebsites.net/";
// export const CounterMobileBaseUrl = (demo) ? "https://aoeventcountermobileuat.azurewebsites.net/" : "https://aoeventcountermobile.azurewebsites.net/";
// export const AoAdminBaseUrl = (demo) ? 'https://aoadminuat.azurewebsites.net/' : 'https://aoadmindev.azurewebsites.net/';;
// export const AoPartnerBaseUrl = (demo) ? "https://aopartneruat.azurewebsites.net/" : "https://aopartner.azurewebsites.net/";;
// export const AoAgentBaseUrl = (demo) ? 'https://aoagentuat.azurewebsites.net/' : 'https://aoagentdev.azurewebsites.net/';
// export const AoPaymentUrl = (demo) ? 'https://aopaymentdev.azurewebsites.net/' : "https://aopayment.azurewebsites.net/";

export const BASE_URL = (demo) ? "https://aointakeuatapi.agingoptions.com" : "https://aointakeapi.azurewebsites.net";
export const AoLoginBaseUrl = (demo) ? "https://aologinuat.agingoptions.com/" : "https://Unilogin.agingoptions.com/";
export const AoIntakeBaseUrl = (demo) ? "https://aointakeformuat.agingoptions.com/" : "https://member.intake.agingoptions.com/";
export const EventBaseUrl = (demo) ? "https://eventuat.agingoptions.com/" : "https://events.agingoptions.com/";
export const CounterBaseUrl = (demo) ? "https://aoeventcounteruat.agingoptions.com/" : "https://Event.counter.agingoptions.com/";
export const CounterMobileBaseUrl = (demo) ? "https://aoeventcountermobileuat.agingoptions.com/" : "https://event.mcounter.agingoptions.com/";
export const AoAdminBaseUrl = (demo) ? 'https://Aoadminuat.agingoptions.com/' : 'https://AOAdmin.agingoptions.com/';;
export const AoPartnerBaseUrl = (demo) ? "https://aopartneruat.agingoptions.com/" : "https://partner.admin.agingoptions.com/";;
export const IntakeEntryBaseUrl = (demo) ? "https://intakeentryformuat.azurewebsites.net/" : "https://intakeentryform.azurewebsites.net/";
export const AoAgentBaseUrl = (demo) ? 'https://aoagentuat.agingoptions.com/' : 'https://agent.agingoptions.com/';
// export const AoAcaddemyUrl = (demo) ? "https://agingoptionsacademy.com/" : "https://agingoptionsacademy.com/"
export const AoPaymentUrl = (demo) ? 'https://aopaymentdev.agingoptions.com/' : "https://aopayment.azurewebsites.net/";


// export const stripePaymentLink=(demo)?'https://buy.stripe.com/test_14k5l31QAbCC5JC8wA':'https://buy.stripe.com/4gw17c5bfcRzaGYbIP'
export const stripePaymentLink=(demo)?'https://buy.stripe.com/test_14k5l31QAbCC5JC8wA':'https://buy.stripe.com/00g038avzg3LaGYdR0'
// export const stripePaymentLink=(demo)?'https://buy.stripe.com/test_14k5l31QAbCC5JC8wA':'https://buy.stripe.com/test_fZe9BjeDm0XY7RKcMR'

// -------------------

export const AoAcaddemyUrl = (demo) ? "https://uat.agingoptionsacademy.com" : "https://agingoptionsacademy.com/"
export const stripePaymentConfirmationUrl=(demo) ?'https://uat.agingoptionsacademy.com//wp-json/custom-api/v1/update-user-confirmation':'https://agingoptionsacademy.com/wp-json/custom-api/v1/update-user-confirmation'

// -------------------  
const AoUrl = {
    getAthenticatePath: '/api/User/AuthenticateLoggedInUser/',
    getSubtenantDetailPath: 'api/Subtenant/get-subtenant-details',
    createAcademyUser: BASE_URL + '/api/User/CreateAcademyUser',
    getExpirydatetime: BASE_URL + '/api/UserValidity/GetUserValidity',
    postAddUserOrder:'/api/UserOrder/AddUserOrder',// 
    upGradeValidity : '/api/UserValidity/UpgradeUserValidity',
}


export default AoUrl;
