import axios from 'axios';
import konsole from "./konsole";
import AoUrl, { BASE_URL, stripePaymentConfirmationUrl } from './url';


// axios.defaults.timeout = 15000;  
axios.defaults.baseURL = BASE_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/json;text/html;charset=utf-8';
// axios.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
// axios.defaults.headers.post['origin'] = 


const invoke = (url, method, body, cancel) => {

    if(cancel){
        cancel();
    }

    try {
        let token = sessionStorage.getItem("AuthToken");

        konsole.log("token ", `Bearer ${token}`);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        konsole.log('URL: '+url);
        konsole.log('method:'+method);
        konsole.log(((method === "POST")?"body": 'params')+JSON.stringify(body));

        return axios({
            cancelToken:new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            method:method,
            url:url,
            params:(method==="GET")?body:null,
            data:(method==="POST" || method === "PUT")?body:null
        });
    }catch(error){
        if(axios.isCancel(error)) {
            konsole.error('Request canceled', error.message);
        } else {
            konsole.error('Something went wrong: ', error.message)
        }
    }
};

const Services = {

    getLoggedInUser: async (userId, appState, loggedInUserId, roleId) => {
        let cancel;
        let url =
            AoUrl.getAthenticatePath +
            `${userId}/${appState}/${loggedInUserId}/${roleId}/`;
        let bookingSlot = {};

        return invoke(url, "GET", bookingSlot, cancel);
    },
    getExpirydatetime: async (loginUserId) => {
        let cancel;
        let url =AoUrl.getExpirydatetime +`/${loginUserId}/18`;
        let body = {};

        return invoke(url, "GET", body, cancel);
    },
    createAcademyUser: async (input) => {
        let cancel;
        let url = AoUrl.createAcademyUser;
        let body = input;

        return invoke(url, "POST", body, cancel);
    },
    getSubtenantDetail: async (subtenantId,isActive) => {
        let cancel;
        let url = AoUrl.getSubtenantDetailPath
        let body = {};
        body['subtenantId'] = subtenantId;
        // body['subtenantLogoUrl'] = subtenantLogoUrl;
        body['isActive'] = isActive;

        return invoke(url, "POST", body, cancel);
    },
    putUpgradeValidity: async (userValidityId,loginUserid,userId,userRoleId,roleId,expriedDate,createdBy) => {
        let cancel;
        let url = AoUrl.upGradeValidity
        let body = {};
        body['userValidityId'] = userValidityId;
        body['loginUserid'] = loginUserid;
        body['userId'] = userId;
        body['userRoleId'] = userRoleId;
        body['roleId'] = roleId;
        body['expriedDate'] = expriedDate;
        body['createdBy'] = createdBy;


        return invoke(url, "PUT", body, cancel);
    },
    postuserorderAddUserOrder:async(adduserobj)=>{
        let cancel;
        let url=AoUrl.postAddUserOrder;


        return invoke(url,"POST",adduserobj,cancel)
    },
    apiCallScripePaymentConfirmation: async ({ userEmail }) => {
        return new Promise((resolve, reject) => {
            let newformData = new FormData()
            newformData.append('user_email', userEmail);
            newformData.append('user_confirmation', 1);
            axios.post(stripePaymentConfirmationUrl, newformData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
                resolve("done");
                konsole.log('Response:', response.data);
            })
                .catch(error => {
                    resolve("err");
                    konsole.error('Error:', error);
                });
        })

    }
}

export default Services;
