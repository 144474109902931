import React, { useRef, useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import commonLib from "../control/commonLib";
import Navbar from "../Navbar";
import konsole from "../network/konsole";
import Services from "../network/Services";
import Payment from "./Payment";
import config from '../config.json'


import {
  AoAgentBaseUrl,
  // LpoLiteBaseUrl,
  AoAcaddemyUrl,
  AoLoginBaseUrl,
  AoIntakeBaseUrl
} from "../network/url";
import { auth0Config_Legacy } from "./auth0";
import { lagecyauth0Cred } from "./auth0Cred";
import LoaderScr from "./LoaderScr";
import { UserContext } from "../App";
import "./betahome.css";
import { Modal, Button } from "react-bootstrap";
import { stripePaymentLink } from "../network/url";
import ToolBoxTiles from "./ToolBox/ToolBoxTiles";

const Betahome = (props) => {
  const demo = config.DEMO;
  const [show, setShow] = useState(false);
  const [expirydate, setexpirydate] = useState();
  const [hoursleft, sethoursleft] = useState("")
  const [GetDiffrenceInDate, setGetDiffrenceInDate] = useState();
  const [minutesleft, setminutesleft] = useState(0)
  const [newDate, setNewDate] = useState("")
  const [showPayment, setshowPayment] = useState(false)
  const { state } = useLocation();
  let pswd = state?.pswd;
  const { setIsLoading } = useContext(UserContext);
  const clickbuttonref = useRef(null);
  // konsole.log("aologin checked", commonLib.getSessionStoarge("stateObj"));
  const stateObj = commonLib.getSessionStoarge("stateObj") !== "undefined" && commonLib.getSessionStoarge("stateObj") !== null ? commonLib.getObjFromStorage("stateObj") : {};
  const userPrimaryDetail = commonLib?.getObjFromStorage("userdata");
  const getRoleId = userPrimaryDetail?.roleId
  const appState = stateObj?.appState;
  const userId = stateObj?.userId;
  const roleId = stateObj?.roleId !== undefined ? JSON.parse(stateObj?.roleId) : [];
  const loginUserId = roleId[0]?.loginUserId;
  const loggenInId = stateObj?.loggenInId;
  const agingoptionsacademy = roleId?.some((data) => data.roleId == 17 || data.roleId == 18);
  const onLyTrialUserAcademy = roleId?.every((data) => data.roleId == 18);
  const lpoLiteUser = roleId?.some((data) => data.roleId == 20 || data.roleId == 9);
  const lpoLagecyUser = roleId?.some((data) => data.roleId == 20);
  const NewlpoUser = roleId?.some((data) => data.roleId == 9);
  const agentUser = roleId?.some((data) => data.roleId == 5);
  const agentUserOnly = roleId?.every((data) => data.roleId == 5);
  const OrderId = window.location.search
  const paymentSuccesStatus = commonLib.getQueryParameters(OrderId, 'TrxnStatus')

  const handleClose = () => {
    setShow(false);
  };
  const continueToRegister = () => {
    window.location.replace(
      `${"http://register.virtualestateplanningsystem.com/?t=2d32f28b-38a8-443b-9203-4a5e0b3949c9"}`
    );
  };
  const handleShow = () => setShow(true);

  if (!!window) {
    if (
      window.performance.getEntriesByType("navigation")[0].type ===
      "back_forward"
    ) {
      onBackButtonEvent();
    }
  }

  useEffect(() => {
    let userId = null;
    let appState = null;
    let loggenInId = null;
    let roleId = null;
    const stateObj = commonLib.getSessionStoarge("stateObj");
    konsole.log("stateobj", stateObj);
    if (stateObj !== "undefined" && stateObj !== null) {
      const stateObj = commonLib.getObjFromStorage("stateObj");
      userId = stateObj?.userId;
      appState = stateObj?.appState;
      loggenInId = stateObj?.loggenInId;
      roleId = stateObj?.roleId;
      getLoggedInUser(userId, appState, loggenInId, roleId);
    } else {
      window.location.replace(`${AoLoginBaseUrl}Account/UnauthorizedAccess`);
    }
    checkExpiryDate()
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ldStripeConnect = urlParams.get('ld_stripe_connect');
    if (paymentSuccesStatus == 'SUCCESS' || ldStripeConnect == 'success') {
      alert("Payment Success")
    }
  }, [])

  const checkExpiryDate = () => {
    setIsLoading(true)
    let promises = Services.getExpirydatetime(loginUserId);
    promises
      .then(function (response) {
        setIsLoading(false)
        let data = response?.data?.data
        const expiryDate = new Date(response?.data?.data?.expriedDate?.slice(0, 10));
        const createdOn = new Date(response?.data?.data?.createdOn?.slice(0, 10));


        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        const pdat = year + "-" + month + '-' + date;
        konsole.log("pdate", pdat)
        const presentDate = new Date(year + "-" + month + '-' + date);
        konsole.log("exp", expiryDate, "today", presentDate);
        const differenceMs = presentDate - expiryDate
        const daysLeft = Math.floor(differenceMs / (1000 * 60 * 60 * 24));/////// after  this new
        var currentDate = new Date();
        var targetDate = new Date(response?.data?.data?.expriedDate);
        var createdDate = new Date(response?.data?.data?.createdOn);
        var timeDifference = targetDate - currentDate;
        var checkDaysLeft = targetDate - createdDate;
        var GetDiffrenceInDate = Math.floor(checkDaysLeft / (1000 * 60 * 60 * 24));
        setGetDiffrenceInDate(GetDiffrenceInDate)
        var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        var hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        // var daysDifference = 0
        // var hoursDifference = 0

        /// get only date
        const datetimeString = response?.data?.data?.expriedDate;
        const datee = new Date(datetimeString);
        const years = datee.getFullYear();
        const months = datee.getMonth() + 1; // Months are zero-based
        const day = datee.getDate();
        const formattedDate = `${day.toString().padStart(2, '0')}-${months.toString().padStart(2, '0')}-${years}`;
        setNewDate(formattedDate)

        if (daysDifference > 0 && hoursDifference > 0) {
          setexpirydate(Math.abs(daysDifference))
          sethoursleft(hoursDifference)
        } else if (daysDifference == 0 && hoursDifference > 0) {
          setexpirydate(0)
          sethoursleft(hoursDifference)
        }
        else if (daysDifference <= 0 && hoursDifference <= 0) {
          setexpirydate("Expired")
        }

        //  (daysDifference > 0) ? setexpirydate(Math.abs(daysDifference)),sethoursleft(hoursDifference) : (daysDifference == 0) ? setexpirydate("Expire Today") : setexpirydate("Expired")
        // (differenceMs < 0) ? setexpirydate("Expired") : (differenceMs == 0) ? setexpirydate("Expire Today") : setexpirydate("Expired")
        if (paymentSuccesStatus == "SUCCESS") {
          const currentDate = new Date();
          const oneYearFromNow = new Date(currentDate);
          oneYearFromNow.setDate(currentDate.getDate() + 365);
          // upGradeValidityPeriod(data?.userValidityId, data?.loginUserid, data?.userId, data?.userRoleId, data?.roleId, oneYearFromNow, data?.userId)

        }
      })

      .catch((err) => {
        setIsLoading(false)
        konsole.log("err", err);
        setexpirydate(err?.response?.statusText)
      });
  }

  const getLoggedInUser = (userId, appState, loggenInId, roleId) => {
    const userRoleName =
      roleId !== undefined && roleId?.length > 0
        ? JSON.parse(roleId).filter(
          (data) =>
            data.roleId == 9 ||
            data.roleId == 17 ||
            data.roleId == 5 ||
            data.roleId == 18 ||
            data.roleId == 20
        )[0].roleId
        : null;
    let promises = Services.getLoggedInUser(
      userId,
      appState,
      loggenInId,
      userRoleName
    );
    promises
      .then((response) => {
        konsole.log("log");
      })
      .catch((err) => {
        konsole.log("err", err);
        window.location.replace(`${AoLoginBaseUrl}Account/UnauthorizedAccess`);
      })
      .finally(() => {
        konsole.log("finish");
      });
  };

  function onBackButtonEvent(e) {
    let referrer = document.referrer;
    if (referrer === "http://localhost:3000/") {
      // if (referrer === AoLoginBaseUrl) {
      sessionStorage.removeItem("stateObj");
      window.location.replace(`${AoLoginBaseUrl}Account/UnauthorizedAccess`);
    }
  }

  const redirectToRespectivePage = (e) => {
    konsole.log("lpoLiteUser", lpoLiteUser);
    if (lpoLiteUser) {
      if (NewlpoUser) {
        let tokenKey = `appState=${appState}&userId=${userId}&roleId=${9}&loggenInId=${loggenInId}`;
        window.open(
          `${AoIntakeBaseUrl}?token=${window.btoa(tokenKey)}`,
          "_blank"
        );
      } else if (lpoLagecyUser) {
        authlagecyfun();
      }
    }
  };

  const authlagecyfun = () => {
    const authCredential = {
      realm: lagecyauth0Cred.realm,
      username: userPrimaryDetail?.primaryEmailId,
      password: commonLib.reversePassword(window?.atob(pswd)),
    };

    konsole.log("authCredential", authCredential);

    setIsLoading(true);
    konsole.log("authlagecy");
    auth0Config_Legacy().login(authCredential, (err, authResult) => {
      if (err) {
        konsole.log("errwerr", err);
      }
      if (authResult) {
        //konsole.log("authResult", authResult);
      }
    });
  };
  const agentUserFunc = () => {
    if (agentUser) {
      let tokenKey = `appState=${appState}&userId=${userId}&roleId=${5}&loggenInId=${loggenInId}`;
      window.open(`${AoAgentBaseUrl}?token=${window.btoa(tokenKey)}`, "_blank");
    }
  };

  let switchOfAoAcaddemyFuc = false;
  const AoAcaddemyFuc = () => {
    if (switchOfAoAcaddemyFuc) return;
    switchOfAoAcaddemyFuc = true;

    if (onLyTrialUserAcademy == true && expirydate == "Expired") return;

    if (agingoptionsacademy) {
      clickbuttonref.current.submit();
    } else if (!agentUserOnly) {
      konsole.log("AoAcaddemyFuc");
      const input = {
        subtenantId: userPrimaryDetail?.subtenant_Id,
        signUpPlateform: 12,
        user: {
          roleId: 17,
          firstName: userPrimaryDetail.loginUserName,
          lastName: userPrimaryDetail.loginUserName,
          emailAddress: userPrimaryDetail?.loginUserEmailId,
          userName: userPrimaryDetail?.loginUserEmailId,
          packageCode: null,
        },
      };
      konsole.log("input", input)
      const createUserAcademy = Services.createAcademyUser(input);
      createUserAcademy
        .then((response) => {
          konsole.log("data response", response);
          clickbuttonref.current.submit();
        })
        .catch((error) => {
          var checkErrorMessage = error?.response?.data?.messages?.some(item => item.includes("Can not assign requested role AO-Academy Users, requested role is already assigned "));
          konsole.log("error", error.response.data.messages, "console", checkErrorMessage)
          if (checkErrorMessage == true) {
            clickbuttonref.current.submit();
          }
        }).finally(() => { switchOfAoAcaddemyFuc = false });
    }
  };
  const renewNowPayment = (e) => {
    e.stopPropagation();
    window.location.replace(stripePaymentLink)
  }



  const returnCommingSoon = (className) => {
    if (lpoLiteUser == false && agentUser == false) {
      return <div className={className}><span>COMING SOON</span></div>
    }
  }


  console.log('agentUseragentUseragentUser', agentUser, lpoLiteUser)
  console.log('GetDiffrenceInDate', GetDiffrenceInDate)
  return (
    <>
      <div>
        <form action={AoAcaddemyUrl} target="_blank" method="post" ref={clickbuttonref}>
          <input
            type="hidden"
            name="userName"
            value={userPrimaryDetail?.loginUserEmailId}
          />
        </form>
        <div className="">
          <div className="background-Image-Div">
            <Navbar />
            {/* *******************************************_________PORTAL TOP CONTENT________****************************************** */}
            <div className="row">
              <div className="col-md-12 col-xs-12 Yellow-imge-Div">
                <img src="White_Shallow_Swoop.png" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <div className=" col col-xs-11 col-sm-11" style={{ position: "absolute", top: "0" }}>
                <div className="Heading-Div">
                  <h2>Welcome to The Portal</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-xs-12 col-sm-12" style={{ position: "absolute", top: "50px" }}  >
                <div className="Sub-Heading-Div">
                  <h5>
                    24/7/365 access to the tools and information you need to
                    create a successful retirement on your terms.
                  </h5>
                </div>
              </div>
            </div>


            {/* *******************************************_________PORTAL TOP CONTENT________****************************************** */}

            <div className="row d-flex justify-content-evenly align-items-start  mb-5">

              {/* *******************************************_________ACADEMY TILE CONTENT________****************************************** */}
              <div className={!agentUserOnly ? `main-card card shadow-lg p-0 cursor-pointer` : `card shadow-lg p-0  main-card-11`}
                style={{ backgroundColor: "#62011D", display: "", }}>
                <a style={{ textDecoration: "none" }} onClick={() => AoAcaddemyFuc()}>
                  <div className="card-body inner-CardBody " onClick={() => AoAcaddemyFuc()}>
                    <div className="d-flex justify-content-center">
                      <img src="Academy-Gold.png" className="img-fluid cardimg" onClick={() => AoAcaddemyFuc()} />
                    </div>

                    <h5 className="card-title-12-agingoptions-academy text-center" style={{ marginBottom: '20px', marginTop: "9px" }}>AGINGOPTIONS ACADEMY</h5>

                    {(agentUser == false && lpoLiteUser == false) && <>

                      {(expirydate !== "Expired" && GetDiffrenceInDate < 31) ? <>
                        <div className="coming-soon2" onClick={(e) => renewNowPayment(e)}>
                          <span title="Click for Payment">Upgrade Plan</span>
                        </div>
                      </> : (expirydate == "Expired") ? <>
                        <div className="coming-soon2" onClick={(e) => renewNowPayment(e)}>
                          <span>Renew Now</span>
                        </div>
                      </> : ""
                      }

                      {/* *********************************_____________TIMER BLOCK____________*********************************** */}
                      {(expirydate !== "Expired") ?
                        <div className="coming-soonnew d-flex justify-content-center align-items-center" onClick={() => AoAcaddemyFuc()}>
                          <span>
                            <span className="fw-bold me-1">Time Left: </span>
                            <span className={expirydate == 0 ? "text-danger fw-bold p-1 me-1" : "fw-bold p-1 ms-1 me-1"} style={{ backgroundColor: "gainsboro", borderRadius: "20%" }}> {expirydate}</span>
                            Days
                            <span className="fw-bold p-1 me-1 ms-1" style={{ backgroundColor: "gainsboro", borderRadius: "20%" }}>{hoursleft}</span>
                            Hours
                          </span>
                        </div> : <div className="text-center pt-2 pb-2 bottom-card-tittle1 "> Expired</div>}
                    </>}
                    {/* *********************************_____________TIMER BLOCK____________*********************************** */}
                  </div>
                  <div className="text-center  pt-2 bottom-card-tittle " style={{ borderTop: '1px solid #DDDDDD' }} onClick={() => AoAcaddemyFuc()}>
                    LEARN
                  </div>
                </a>
              </div>

              {/* ------------------------------ toolbox tile--------------------------------------------------------------------------------------------------- */}

              {/* acedmy */}
              <div className="card shadow-lg p-0  main-card " style={{ backgroundColor: "#CD9A27" }} onClick={handleShow}>
                <a style={{ textDecoration: "none" }}
                // href="https://agingoptions.com/myportal/estate/"
                >
                  <div className="card-body inner-CardBody ">
                    <div className="d-flex justify-content-center">
                      <img src="EstatePlanningDocs-Red.png " className="img-fluid cardimg" />
                    </div>
                    <h5 className="card-title2  text-center">TOOLBOX</h5>
                  </div>
                  <div className="text-center pt-2 bottom-card-tittle">CREATE</div>
                </a>
              </div>
              {/* ------------------------------ toolbox tile--------------------------------------------------------------------------------------------------- */}

              {lpoLiteUser ? (
                <div className="card shadow-lg p-0  main-card" style={{ backgroundColor: "#CD9A27" }} >
                  <a style={{ textDecoration: "none" }} onClick={() => lpoLiteUser ? redirectToRespectivePage() : ""}  >
                    <div className="card-body inner-CardBody">
                      <div className="d-flex justify-content-center">
                        <img src="LPO-Red.png" className="img-fluid cardimg" />
                      </div>
                      <h5 className="card-title-12 text-center" > LIFEPLAN ORGANIZER</h5>
                      {returnCommingSoon("coming-soon1")}
                    </div>
                    <div className="text-center pt-2 bottom-card-tittle">ORGANIZE</div>
                  </a>
                </div>
              ) : (
                <div className="card shadow-lg p-0  main-card-11" style={{ backgroundColor: "#CD9A27" }} >
                  <a style={{ textDecoration: "none" }} onClick={() => lpoLiteUser ? redirectToRespectivePage() : ""}>
                    <div className="card-body inner-CardBody-11">
                      <div className="d-flex justify-content-center">
                        <img src="LPO-Red.png" className="img-fluid cardimg" />
                      </div>
                      {returnCommingSoon("coming-soon1")}
                      <h5 className={`card-title-12 text-center${lpoLiteUser == false && agentUser == false ? "" : ""}`}> LIFEPLAN ORGANIZER</h5>
                    </div>
                    <div className="text-center pt-2 bottom-card-tittle"> ORGANIZE</div>
                  </a>
                </div>
              )}

              {agentUser ? (
                <div className="card shadow-lg p-0  main-card " style={{ backgroundColor: "#62011D" }}>
                  <a style={{ textDecoration: "none" }} onClick={() => (agentUser ? agentUserFunc() : "")}>
                    <div className="card-body inner-CardBody">
                      <div className="d-flex justify-content-center">
                        <img src="guide.png" className="img-fluid cardimg" />
                      </div>
                      {returnCommingSoon("coming-soon")}
                      <h5 className="card-title4 text-center" style={{ marginTop: '62px' }}>guidance</h5>
                    </div>
                    <div className="text-center pt-2 bottom-card-tittle"> Guide</div>
                  </a>
                </div>
              ) : (
                <div className="card shadow-lg p-0  main-card-11" style={{ backgroundColor: "#62011D" }}>
                  <a style={{ textDecoration: "none" }} onClick={() => (agentUser ? agentUserFunc() : "")}>
                    <div className="card-body inner-CardBody-11">
                      <div className="d-flex justify-content-center">
                        <img src="guide.png" className="img-fluid cardimg" />
                      </div>
                      <h5 className={`card-title-11 text-center${lpoLiteUser == false && agentUser == false ? "mt-" : ""}`}>guidance</h5>
                      {returnCommingSoon("coming-soon")}
                    </div>
                    <div className="text-center pt-2 bottom-card-tittle">Guide</div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------ToolBox Tiles----------------------------------- */}

      {(show) ?
        <ToolBoxTiles show={show} handleClose={handleClose} />
        : ''}
      {/* --------------------------ToolBox Tiles----------------------------------- */}

      {showPayment == true ? <Payment showPayment={showPayment} setshowPayment={setshowPayment} /> : ''}
    </>
  );
};

export default Betahome;